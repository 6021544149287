import React from 'react';

const FetchAllLiteratures = ({ literatures }) => {
  if (!literatures) return <div>Loading...</div>;
  if (literatures.length === 0) return <div>No literature posts found.</div>;

  return (
    <div className='AllLiteraturePosts'>
      <div className="m-3 literaturePost">
        {literatures.map((post) => (
          <div className='literatureContent' key={post._id}>
            <h2>{post.title}</h2>
            <br />
            <p>{post.description}</p>
            <div className='img-container'>
            {post.imagePath && <img src={post.imagePath} alt={post.title} className="img-fluid" />}
            </div>
            <hr />
            <a className='btn btn-dark' href={`/literatures/${post.slug}`}>Read more</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FetchAllLiteratures;
