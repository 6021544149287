import React from "react";

const FetchAllBlogposts = ({ blogposts }) => {
  if (!blogposts) return <div>Loading...</div>;
  if (blogposts.length === 0) return <div>No blog posts found.</div>;

  return (
    <div className="AllBlogPosts">
      <div className="m-3 blogPost">
        {blogposts.map((post) => (
          <div className="blogPostContent" key={post._id}>
            <h2>{post.title}</h2>
            <br />
            <p>{post.description}</p>
            <hr />
            <a className="btn btn-dark" href={`/articles/${post.slug}`}>
              Read more
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FetchAllBlogposts;
