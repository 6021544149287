import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const FetchSingleLyricpost = () => {
  const { slug } = useParams();
  const [lyric, setLyric] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLyric = async () => {
      try {
        const response = await axios.get(`/api/lyrics/${slug}`);
        setLyric(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.response ? err.response.data.message : 'Server error');
        setLoading(false);
      }
    };

    fetchLyric();
  }, [slug]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!lyric) return <div>No lyric post found.</div>;

  return (
    <div className='SingleLyricPost mt-5'>
      <h2>{lyric.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: lyric.sanitizedHtml }} />
      <p>{lyric.description}</p>
      {lyric.imagePath && <img src={lyric.imagePath} alt={lyric.title} className="img-fluid" />}
      <hr />
      <a className="btn btn-dark mt-3" href={`/lyrics`}>
        Back Lyrics
      </a>
    </div>
  );
};

export default FetchSingleLyricpost;
