import React from 'react';

const FetchAllLyrics = ({ lyrics }) => {
  if (!lyrics) return <div>Loading...</div>;
  if (lyrics.length === 0) return <div>No lyric posts found.</div>;


  return (
    <div className='AllLyricPosts mt-5'>
      <div className="m-3 lyricPost">
        {lyrics.map((post) => (
          <div className='lyricContent mb-5' key={post._id}>
            <h2>{post.title}</h2>
            <br />
            <p>{post.description}</p>
            <div className='img-container'>
            {post.imagePath && <img src={post.imagePath} alt={post.title} className="img-fluid" />}
            </div>
            <a className='btn btn-dark' href={`/lyrics/${post.slug}`}>Read more</a>
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FetchAllLyrics;
