import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import FetchAllLiteratures from '../components/Literatures/fetchAllLiteratureposts';
import NewLiteraturePost from '../components/Literatures/newLiteraturepost';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

function Literatures() {
  const [showModal, setShowModal] = useState(false);
  const [literatures, setLiteratures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const fetchLiteratures = async () => {
    try {
      const response = await axios.get('/api/literatures');
      setLiteratures(response.data.literatures);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching literature posts:', err);
      setError(err.response ? err.response.data.message : 'Server error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLiteratures();
  }, []);

  const handleLiteraturePostCreated = (newLiterature) => {
    setLiteratures([newLiterature, ...literatures]); // Prepend the new literature post to the list
    setShowModal(false);
    fetchLiteratures(); // Fetch updated list of literature posts
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div>
        <h3>Literatures</h3>
        {user && user.isAdmin && (
          <Button variant="success" onClick={handleShow}>
            New Literature Post
          </Button>
        )}
        <FetchAllLiteratures literatures={literatures}/>
        {user && user.isAdmin && (
          <NewLiteraturePost
            show={showModal}
            handleClose={handleClose}
            onLiteraturePostCreated={handleLiteraturePostCreated}
          />
        )}
      </div>
    </>
  );
}

export default Literatures;
