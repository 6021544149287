import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

const NewFavimage = ({ show, handleClose, onFavimageCreated }) => {
  const [title, setTitle] = useState("");
  const [markdown, setMarkdown] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/favimages", {
        title,
        markdown,
        imagePath,
      });
      onFavimageCreated(response.data);
      setTitle("");
      setMarkdown("");
      setImagePath("");
      handleClose();
    } catch (err) {
      console.error("Error submitting favImage:", err); // Improved logging
      setError(err.response ? err.response.data.message : "Server error");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New Favimage</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formMarkdown">
            <Form.Label>Markdown</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={markdown}
              onChange={(e) => setMarkdown(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formImagePath">
            <Form.Label>Image Path</Form.Label>
            <Form.Control
              type="text"
              value={imagePath}
              onChange={(e) => setImagePath(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NewFavimage;
