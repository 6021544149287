import React from 'react';

const FetchAllFavimages = ({ favimages }) => {
  if (!favimages) return <div>Loading...</div>;
  if (favimages.length === 0) return <div>No favorite images found.</div>;

  return (
    <div className='AllFavimages'>
      <div className="m-3 favimage">
        {favimages.map((image) => (
          <div className='favimageContent' key={image._id}>
            <div className='img-container'>
              {image.imagePath && <img src={image.imagePath} alt={image.title} className="img-fluid" />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FetchAllFavimages;
