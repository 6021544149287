import React from 'react';

const AllUsers = ({ users }) => {
  if (!users) return <div>Loading...</div>;
  if (users.length === 0) return <div>No users found.</div>;


  return (
    <div className='AllUsers mt-5'>
      <div className="m-3 users">
        {users.map((user) => (
          <div className='user-details container mb-5' key={user._id}>
          <p><b>Name:</b>  {user.firstname} {user.surname}</p>
            <br />
            <p><b>Email:</b> {user.email}</p>
            <p><b>Password:</b> {user.password}</p>
            <p><b>Last Login:</b> {user.lastLogin ? new Date(user.lastLogin).toLocaleString() : 'Never logged in'}</p>
            <p><b>User Type:</b> {user.isAdmin ? 'Admin' : 'User'}</p>
            <a className='btn btn-info m-2' href={`/users/${user._id}`}>Edit</a>
            <a className='btn btn-danger m-2' href={`/users/${user._id}`}>Delete</a>
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllUsers;
