import { Route, Routes, useLocation } from "react-router-dom";
import "./assets/styles/App.css";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Literatures from "./pages/Literatures";
import Lyrics from "./pages/Lyrics";
import { Navbar } from "./layout/Navbar";
import Login from "./pages/Login";
import SingleLiteraturepost from "./pages/SingleLiteraturepost";
import { AuthProvider, useAuth } from "./context/AuthContext";
import LoginAuth from "./components/auth/LoginAuth";
import AdminUser from "./components/auth/AdminUser";
import AdminDashboard from "./pages/AdminDashboard";
import SingleBlogpost from "./pages/SingleBlogpost";
import SingleLyricpost from "./pages/SingleLyricpost";

function AppContent() {
  const { user } = useAuth();
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  
    return (
        <>
          <Navbar showFullNav={!isLoginPage} />
          <div className="container">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<LoginAuth />}>
                <Route path="/" element={<Home />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/articles/:slug" element={<SingleBlogpost />} />
                <Route path="/literatures" element={<Literatures />} />
                <Route path="/literatures/:slug" element={<SingleLiteraturepost />} />
                <Route path="/lyrics" element={<Lyrics />} />
                <Route path="/lyrics/:slug" element={<SingleLyricpost />} />
                <Route path="/admin" element={<AdminUser component={AdminDashboard} />} />
              </Route>
            </Routes>
          </div>
        </>
      );
    }
    
    function App() {
      return (
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      );
    }
    
    export default App;