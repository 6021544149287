import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';

const FetchSingleBlogpost = () => {
  const { slug } = useParams();
  const [blogpost, setBlogpost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogpost = async () => {
      try {
        const response = await axios.get(`/api/articles/${slug}`);
        setBlogpost(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.response ? err.response.data.message : 'Server error');
        setLoading(false);
      }
    };

    fetchBlogpost();
  }, [slug]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!blogpost) return <div>No blogpost post found.</div>;

  const blogpostDate = new Date(blogpost.createdAt);

  return (
    <>
    <div className='SingleBlogpost'>
      <h2>{blogpost.title}</h2>
      <p>{blogpostDate.toLocaleDateString()}</p>
      <div dangerouslySetInnerHTML={{ __html: blogpost.sanitizedHtml }} />
      <p className="blogContent">{blogpost.description}</p>
      <hr />
      <a className="btn btn-dark mt-3" href={`/blog`}>
        Back Blog
      </a>
    </div>
    </>
  );
};

export default FetchSingleBlogpost;
