import React from 'react';
import FetchSingleLiteraturepost from '../components/Literatures/fetchSingleLiteraturepost';

const SingleLiteraturepost = () => {
  return (
    <div>
      <FetchSingleLiteraturepost />
    </div>
  );
};

export default SingleLiteraturepost;
