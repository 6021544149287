import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import FetchAllLyrics from '../components/Lyrics/fetchAllLyricsposts';
import NewLyricPost from '../components/Lyrics/newLyricpost';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

function Lyrics() {
  const [showModal, setShowModal] = useState(false);
  const [lyrics, setLyrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const fetchLyrics = async () => {
    try {
      const response = await axios.get('/api/lyrics');
      setLyrics(response.data.lyrics);
      setLoading(false);
    } catch (err) {
      setError(err.response ? err.response.data.message : 'Server error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLyrics();
  }, []);

  const handleLyricPostCreated = (newLyric) => {
    setLyrics([newLyric, ...lyrics]); // 
    setShowModal(false);
    fetchLyrics(); // Fetch updated list of lyrics
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div>
        <h3>Lyrics</h3>
        {user && user.isAdmin && (
          <Button variant="success" onClick={handleShow}>
            New Lyric Post
          </Button>
        )}
        <FetchAllLyrics lyrics={lyrics} />
        {user && user.isAdmin && (
          <NewLyricPost
            show={showModal}
            handleClose={handleClose}
            onLyricPostCreated={handleLyricPostCreated}
          />
        )}
      </div>
    </>
  );
}

export default Lyrics;