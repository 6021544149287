import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import NewBlogPostModal from '../components/Blogposts/newBlogpost';
import FetchAllBlogposts from '../components/Blogposts/fetchAllBlogposts';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

function Blog() {
  const [showModal, setShowModal] = useState(false);
  const [blogposts, setBlogposts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const fetchBlogposts = async () => {
    try {
      const response = await axios.get('/api/articles');
      setBlogposts(response.data.articles);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching blogposts:', err);
      setError(err.response ? err.response.data.message : 'Server error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogposts();
  }, []);

  const handleBlogPostCreated = (newBlogPost) => {
    setBlogposts([newBlogPost, ...blogposts]); // Prepend the new blog post to the list
    setShowModal(false);
    fetchBlogposts(); // Fetch updated list of blog posts
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
    <div>
      <h3>Blog Posts</h3>
      {user && user.isAdmin && (
          <Button variant="success" onClick={handleShow}>
            New Blog Post
          </Button>
        )}
        <FetchAllBlogposts blogposts={blogposts} />
        {user && user.isAdmin && (
      <NewBlogPostModal
        show={showModal}
        handleClose={handleClose}
        onBlogPostCreated={handleBlogPostCreated}
      />
      )}
    </div>
    </>
  );
}

export default Blog;
