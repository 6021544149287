import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import FetchAllFavimages from "../components/FavImages/fetchAllFavImages";
import NewFavimage from "../components/FavImages/newFavImage";
import axios from "axios";
import { useAuth } from "../context/AuthContext";

function Home() {
  const [showModal, setShowModal] = useState(false);
  const [favimages, setFavimages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const fetchFavimages = async () => {
    try {
      const response = await axios.get("/api/favimages");
      setFavimages(response.data.favimages || []); // Ensure it's an array
      setLoading(false);
    } catch (err) {
      console.error("Error fetching favImages:", err); // Improved logging
      setError(err.response ? err.response.data.message : "Server error");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFavimages();
  }, []);

  const handleFavimageCreated = (newFavimage) => {
    setFavimages([newFavimage, ...favimages]);
    setShowModal(false);
    fetchFavimages(); // Fetch updated list of favimages
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div>
        <h3>Home</h3>
        {user && user.isAdmin && (
          <Button variant="success" onClick={handleShow}>
            New FavImage Post
          </Button>
        )}
        <FetchAllFavimages favimages={favimages} />
        {user && user.isAdmin && (
          <NewFavimage
            show={showModal}
            handleClose={handleClose}
            onFavimageCreated={handleFavimageCreated}
          />
        )}
      </div>
    </>
  );
}

export default Home;
