import React from 'react';
import FetchSingleBlogpost from '../components/Blogposts/fetchSingleBlogpost';

const SingleBlogpost = () => {
  return (
    <div>
      <FetchSingleBlogpost />
    </div>
  );
};

export default SingleBlogpost;