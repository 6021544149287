import React, { useState, useEffect } from 'react';
import AllUsers from '../components/users/allUsers';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

function AdminDashboard() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();



  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/users');
      setUsers(response.data.users);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError(err.response ? err.response.data.message : 'Server error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);



  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!user || !user.isAdmin) {
    return <div>Access denied. Admins only.</div>;
  }

  return (
    <div>
      <h3>Admin Dashboard</h3>
      <p>Hello admin {user.firstname} {user.surname}!</p>
      <AllUsers users={users} />
    </div>
  );
}

export default AdminDashboard;
