import React from 'react';
import FetchSingleLyricpost from '../components/Lyrics/fetchSingleLyricpost';

const SingleLyricpost = () => {
  return (
    <div>
      <FetchSingleLyricpost />
    </div>
  );
};

export default SingleLyricpost;
