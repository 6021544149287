import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Navbar } from "../layout/Navbar";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await login(email, password);
      navigate("/"); // Redirect to home page or wherever you want
    } catch (err) {
      setError("Failed to login. Please check your credentials and try again.");
    }
  };

  return (
    <>
      <h2 className="mt-5">Login</h2>
      <div className="container form-control mt-3">
        <form className="loginForm" onSubmit={handleSubmit}>
          <div className="d-flex">
            <label htmlFor="email">Email</label>
            <input
              className="form-control ml-2"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="email"
            />
          </div>
          <div className="d-flex">
            <label htmlFor="password">Password</label>
            <input
              className="form-control ml-2"
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password"
            />
          </div>
          <button className="btn btn-primary w-100 mb-2" type="submit">
            Login
          </button>
          {error && <p>{error}</p>}
        </form>
      </div>
    </>
  );
};

export default Login;
