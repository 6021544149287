import React, { useState } from 'react';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export function Navbar({ showFullNav }) {
  const { user, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="nav">
      <Link to="/" className="site-name">The Number Twelfth</Link>
      <div className="menu-icon" onClick={toggleMenu}>
        &#9776;
      </div>
      {showFullNav && (
        <ul className={`menu ${menuOpen ? 'open' : ''}`}>
          <CustomLink to="/">Home</CustomLink>
          <CustomLink to="/blog">Blog</CustomLink>
          <CustomLink to="/literatures">Literatures</CustomLink>
          <CustomLink to="/lyrics">Lyrics</CustomLink>
          {user && user.isAdmin && (
            <>
              <CustomLink to="/admin">Admin</CustomLink>
            </>
          )}
        </ul>
      )}
      {showFullNav && <button className='btn btn-danger' onClick={logout}>Sign Out</button>}
    </nav>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>{children}</Link>
    </li>
  );
}
