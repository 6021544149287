import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const FetchSingleLiteraturepost = () => {
  const { slug } = useParams();
  const [literature, setLiterature] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLiterature = async () => {
      try {
        const response = await axios.get(`/api/literatures/${slug}`);
        setLiterature(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.response ? err.response.data.message : 'Server error');
        setLoading(false);
      }
    };

    fetchLiterature();
  }, [slug]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!literature) return <div>No literature post found.</div>;

  return (
    <div className='SingleLiteraturePost'>
      <h2>{literature.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: literature.sanitizedHtml }} />
      <p>{literature.description}</p>
      {literature.imagePath && <img src={literature.imagePath} 
      alt={literature.title} className="img-fluid" />}
      <hr />
      <a className="btn btn-dark mt-3" href={`/literatures`}>
        Back Literatures
      </a>
    </div>
    
  );
};

export default FetchSingleLiteraturepost;
