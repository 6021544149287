import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

const NewBlogPostModal = ({ show, handleClose, onBlogPostCreated }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [markdown, setMarkdown] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/articles", {
        title,
        description,
        markdown,
      });
      onBlogPostCreated(response.data);
      setTitle("");
      setDescription("");
      setMarkdown("");
      handleClose();
    } catch (err) {
      setError(err.response ? err.response.data.message : "Server error");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New Blog Post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formMarkdown">
            <Form.Label>Markdown</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={markdown}
              onChange={(e) => setMarkdown(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3 blogPostContent" controlId="formDescription">
            <Form.Label>Blog Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NewBlogPostModal;
